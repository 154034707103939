﻿%dropdown-list {
	position: relative;
	text-align: left !important;
	display: inline-block;
	margin: 0;

	dt {
		position: relative;
		z-index: 1;
		padding: 1rem 2.5rem 1rem 1.25rem;
		background: $color-neutral;
		border-radius: $border-radius;
		cursor: pointer;
		outline: 0;

		&::after {
			@include icon($icon-arrow-down);
			position: absolute;
			top: 50%;
			right: 1.25rem;
			transform: translateY(-50%);
		}
	}

	dd {
		position: absolute;
		overflow: hidden;
		width: auto;
		min-width: 100%;
		margin: 0;
		background: $color-neutral;
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		list-style: none;
		visibility: hidden;

		ul {
			justify-content: center;
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				width: 100%;
				margin: 0;

				&.active {
					font-weight: bold;
				}

				a {
					display: block;
					width: 100%;
					padding: 0.5rem 1.25rem;
					border-radius: 0;
					text-decoration: none;
				}
			}
		}
	}


	@mixin open {
		z-index: 2;
		box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);

		dt,
		dt:focus {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			&::before {
				// Used for dropdown list
				display: block;
			}

			&::after {
				content: $icon-arrow-up;
			}
		}

		dd {
			visibility: visible;
			box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
		}
	}

	&.clicked.open {
		@include open;
	}

	&:not(.clicked):focus-within {
		@include open;
	}
}

.dropdown-list {
	@extend %dropdown-list;
}

dl.forms-list {
	@extend %dropdown-list;
	display: inline-block;
	min-width: 100%;
	text-align: left;
	color: $color-text;

	@include breakpoint(t) {
		min-width: 42rem;
	}

	dt,
	dd {
		background: $color-neutral;
	}

	dt {
		font-size: 100%;
		font-weight: normal;

		&::after {
			color: $color-green;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	a {
		display: block;
		padding: 0.813rem 2rem;
		outline: none;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

ul.list,
ul.arrow-list {
	list-style: none;
	border-top: 0.0625rem solid $color-grey;
	padding: 0;
	margin: 0 0 $line-height;

	li {
		display: block;
		padding: 0.75rem 1rem;
		border-bottom: 0.0625rem solid $color-grey;

		a {
			text-decoration: none;
			transition: color 250ms ease-in-out;

			&:hover,
			&:focus {
				color: $color-green;
			}
		}

		&.download {
			a {
				display: block;
				line-height: 1.5rem;

				&::before {
					@include icon($icon-download);
					color: $color-green;
					font-size: 1.5rem;
					margin-right: 0.5rem;
				}
			}

			span {
				font-weight: 800;
				text-transform: uppercase;
			}
		}
	}
}

ul.arrow-list {
	li {
		a {
			position: relative;
			display: block;

			&::after {
				@include icon($icon-arrow-right);
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
		}
	}
}

dl.list {
	border-top: 0.0625rem solid $color-grey;
	margin: 0 0 $line-height;

	@include breakpoint(t) {
		display: flex;
		flex-wrap: wrap;
	}

	dt,
	dd {
		width: 100%;
		margin: 0;

		@include breakpoint(t) {
			padding: 0.5rem 1rem;
			border-bottom: 0.0625rem solid $color-grey;
		}
	}

	dt {
		padding: 0.5rem 0.5rem 0;
		font-weight: 700;
		position: relative;

		@include breakpoint(l) {
			width: (100% / 3);
		}

		.information {
			display: inline-block;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 100%;
			margin-left: -0.5rem;
			background-color: $color-blue;
			color: $color-neutral;
			text-align: center;
			font-style: normal;
			cursor: pointer;
			transition: background-color 250ms ease-in-out;

			&:hover,
			&:focus {
				background-color: $color-green;
			}

			@include breakpoint(l) {
				margin-right: 0.5rem;
				margin-left: -1rem;
			}
		}

		.tooltip {
			@include z-index(tooltip);
			position: absolute;
			top: -0.75rem;
			left: 0.75rem;
			transform: translate(-1.25rem, -100%);
			width: 100vw;
			max-width: 40rem;
			padding: 1rem;
			background: $color-neutral;
			border-radius: $border-radius;
			font-weight: normal;
			box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
			display: none;

			&::after {
				content: '';
				width: 0;
				height: 0;
				border-left: 1rem solid transparent;
				border-right: 1rem solid transparent;
				border-top: 1rem solid $color-neutral;
				position: absolute;
				bottom: -1rem;
				left: 1rem;

				@include breakpoint(l) {
					left: 50%;
					transform: translate(-50%, -1px);
				}
			}

			@include breakpoint(l) {
				transform: translate(-50%, -100%);
			}

			.close {
				font-size: 1rem;
				position: absolute;
				right: 1.125rem;
				top: 0.625rem;
				cursor: pointer;
			}
		}

		&.clicked.open,
		&:not(.clicked):focus-within {
			.tooltip {
				display: block;
			}
		}
	}

	dd {
		padding: 0 0.5rem 0.5rem;
		border-bottom: 0.0625rem solid transparentize($color-blue, 0.9);

		@include breakpoint(l) {
			width: (100% / 3 * 2);
		}

		a {
			color: $color-blue;
			transition: color 250ms ease-in-out;

			&:hover,
			&:focus {
				color: $color-green;
			}
		}
	}
}

.anchor-list {
	padding: 0;
	border-bottom: 1px solid $color-grey;
	margin: 0 0 1rem;

	li {
		display: inline-block;
		margin: 0 2rem 0 0;

		a {
			display: block;
			padding: 0.5rem 0;
			color: $color-blue;
			font-weight: 800;
			font-size: 1rem;
			text-decoration: none;
			transition: color 250ms ease-in-out;

			&:hover,
			&:focus {
				color: $color-green;
			}
		}
	}
}