﻿.autocomplete {
	width: 100%;
	text-align: left;

	&,
	+ .button {
		@include z-index(autocomplete);
	}

	&::before {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.8);
		content: '';
		opacity: 0;
		transition: opacity 300ms ease-in-out, visibility 0s linear 300ms;
		visibility: hidden;
	}

	&.open {
		.input {
			border-color: $color-neutral;
			border-bottom-left-radius: 0;
		}

		&::before {
			opacity: 1;
			transition-delay: 0s;
			visibility: visible;
		}

		+ .button {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.input,
	.hint {
		width: 100%;
	}

	.hint {
		// Same as placeholder text
		color: rgba($color-text, 0.5);
	}

	.dropdown-menu {
		width: calc(100% - 3.5rem);
		width: 100%;
		background: $color-background;
		border-radius: 0 0 $border-radius $border-radius;
		margin-top: 3.5rem;

		@include breakpoint(t) {
			margin-top: 0;
		}

		.footer,
		.empty {
			padding: 0.5rem 1.5rem;
			margin: 0;
			font-weight: 700;
		}


		.footer {
			border-top: 0.0625rem solid $color-grey;

			a {
				color: $color-blue;
				text-decoration: none;
			}
		}

		.suggestions {
			position: relative;

			@include breakpoint(l) {
				column-count: 2;
				column-gap: $grid-gap;
				position: relative;
			}
		}

		.suggestion {
			page-break-inside: avoid;

			a {
				display: block;
				padding: 0.5rem 1.5rem;
				text-decoration: none;
			}

			figure {
				display: flex;
				break-inside: avoid;
				overflow: hidden;
				min-height: 69px;
				align-items: center;
				background: url('../../images/image-placeholder.svg') 0 50% no-repeat;
				background-size: 103px 69px;
			}

			img {
				flex-shrink: 0;
				transition: all 150ms linear;
			}

			figcaption {
				width: 100%;
				min-width: 0;
				padding-left: 1rem;

				h4 {
					line-height: 1.2rem;
				}

				&:first-child {
					margin-left: 103px;
				}
			}

			h4,
			span {
				overflow: hidden;
				text-overflow: ellipsis;
			}

			span {
				display: block;
				color: $color-text;
				font-weight: 400;
				white-space: nowrap;
			}
		}
	}
}
