﻿$backdrop-opacity: 0.75;
$lg-border-radius-base: 50%;
$lg-theme: $color-neutral;
$lg-toolbar-bg: none;

// Icon
$lg-icon-bg: none;
$lg-icon-color: $color-blue;
$lg-icon-hover-color: $color-green;

// Counter
$lg-counter-color: $color-text;

// Sub html - titile
$lg-sub-html-bg: none;
$lg-sub-html-color: $color-text;

// Thumb
$lg-thumb-bg: $color-neutral;
$lg-theme-highlight: $color-blue;
$lg-border-radius-base: 0;
$lg-thumb-toggle-bg: $color-neutral;
$lg-thumb-toggle-color: $color-blue;
$lg-thumb-toggle-hover-color: $color-green;

@import '~lightgallery.js/src/sass/lightgallery';


.lg-outer {
	padding: 1rem;

	@include breakpoint(l) {
		padding: $grid-gap;
	}

	.lg {
		display: flex;
		flex-direction: column;
		background: $color-neutral;
		max-width: 98%;
	}

	.lg-img-wrap {
		padding: 3rem 1rem;

		@include breakpoint(l) {
			padding: 3rem 1rem 7rem;
		}
	}

	.lg-thumb-item {
		border-radius: $lg-border-radius-base;
		margin-right: 1rem;
		float: none;
		display: inline-block;
		aspect-ratio: 4 / 3;
		height: auto;
	}

	.lg-video-cont,
	.lg-video {
		height: 100% !important;
	}

	.lg-video {
		padding-bottom: 0 !important;
	}

	.lg-thumb-outer,
	.lg-inner {
		position: relative;
	}

	.lg-item {
		//position: relative !important;
	}

	.lg-actions .lg-next,
	.lg-actions .lg-prev {
		background: rgba(#fff, 0.5);
	}
}

.lg-thumb.group {
	overflow: hidden;
	margin: 0 auto;
	font-size: 0;
	text-align: center;
}

.lg-hide-items .lg-toolbar {
	opacity: 1;
	transform: translate3d(0, 0, 0)
}

.lg-sub-html {
	position: static;
	font-size: 1rem;
	font-weight: 800;
	text-align: left;
	padding: 1rem 1.25rem;
}

#lg-counter {
	display: none;
}