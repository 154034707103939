@import "../mixins/breakpoints";
@import "../custom/variables";

// Gutter creation mixin
@mixin columns-gutter($size) {
	$size-half: ($size / 2 * 1px);

	.columns.has-gutter-#{$size} {
		margin: ($size-half * -1);

		&.is-bordered {
			margin-top: ($grid-gap / 2);
			margin-bottom: ($grid-gap / 2);
		}

		.column {
			/*border-color: transparent;
			border-style: solid;
			border-width: ($size-half);*/
			padding: $size-half;
		}
	}
}

// Columns
.columns {
	display: flex;
	flex-wrap: wrap;

	&.has-no-wrap {
		overflow: hidden;
		flex-wrap: nowrap;
	}

	&.is-vertical-aligned {
		align-items: center;
	}

	.image-align-left &,
	&.l-row-reverse {
		@include breakpoint(l) {
			flex-flow: row-reverse;
		}
	}

	&.is-bordered {
		> .column {
			border-left: 1px solid $color-grey;
			border-top: 1px solid transparent;

			&:first-of-type {
				border-left-color: transparent;
			}
		}

		@include breakpoint-min-max(ml,t) {
			&.is-50-ml {
				> .column {
					&:nth-of-type(2n+1) {
						border-left-color: transparent;
					}

					&:nth-child(n+3) {
						border-top-color: $color-grey;
					}
				}
			}
		}

		@include breakpoint-min-max(ts,t) {
			&.is-50-ts {
				> .column {
					&:nth-of-type(2n+1) {
						border-left-color: transparent;
					}

					&:nth-child(n+3) {
						border-top-color: $color-grey;
					}
				}
			}
		}

		@include breakpoint-min-max(t,l) {
			&.is-33-t {
				> .column {
					&:nth-of-type(3n+1) {
						border-left-color: transparent;
					}

					&:nth-child(n+4) {
						border-top-color: $color-grey;
					}
				}
			}
		}

		@include breakpoint(t) {
			&.is-33-t {
				> .column {
					&:nth-of-type(3n+1) {
						border-left-color: transparent;
					}

					&:nth-child(n+4) {
						border-top-color: $color-grey;
					}
				}
			}
		}

		@include breakpoint(l) {
			&.is-25-l {
				> .column {
					&:nth-of-type(4n+1) {
						border-left-color: transparent;
					}

					&:nth-child(n+5) {
						border-top-color: $color-grey;
					}
				}
			}

			&.is-33-l {
				> .column {
					&:nth-of-type(3n+1) {
						border-left-color: transparent;
					}

					&:nth-child(n+4) {
						border-top-color: $color-grey;
					}
				}
			}
		}
	}
}

.column {
	flex: 0 0 auto;
	width: 100%;
}

@mixin columns-breakpoint($size-name, $size, $breakpoint) {

	@if($breakpoint == "b") {

		.columns.is-#{$size-name} {

			> .column {
				width: $size;
			}
		}
	}
	@else {

		.columns.is-#{$size-name}-#{$breakpoint} {

			> .column {
				@include breakpoint($breakpoint) {
					width: $size;
				}
			}
		}
	}
}

@mixin column-breakpoint($size-name, $size, $breakpoint) {

	@if($breakpoint == "b") {

		.column.is-#{$size-name} {
			width: $size;
		}
	}
	@else {

		.column.is-#{$size-name}-#{$breakpoint} {

			@include breakpoint($breakpoint) {
				width: $size;
			}
		}
	}
}
