﻿$color-facebook: #3b5998;
$color-instagram: #e1306c;
$color-linkedin: #0e76a8;
$color-pinterest: #e60023;
$color-twitter: #1da1f2;
$color-vimeo: #1ab7ea;
$color-youtube: #ff0000;

$social-media-colors: (
	'facebook' : (
		'base': $color-facebook,
		'text' : $color-neutral
	),
	'instagram' : (
		'base': $color-instagram,
		'text' : $color-neutral
	),
	'linkedin' : (
		'base': $color-linkedin,
		'text' : $color-neutral
	),
	'pinterest' : (
		'base': $color-pinterest,
		'text' : $color-neutral
	),
	'twitter' : (
		'base': $color-twitter,
		'text' : $color-neutral
	),
	'vimeo' : (
		'base': $color-vimeo,
		'text' : $color-neutral
	),
	'youtube' : (
		'base': $color-youtube,
		'text' : $color-neutral
	)
);

@function social-media-color($base, $shade: base) {
	@return map-get(map-get($social-media-colors, $base), $shade);
}

.social-media {
	overflow: hidden;
	padding: 0;
	margin: 0;
	font-size: 0;
	list-style: none;
	text-align: center;

	li {
		display: inline-block;
		margin: 0.5rem;

		@each $name in map-keys($social-media-colors) {
			&.#{$name} > a {
				background-color: social-media-color($name, base);
				color: social-media-color($name, text);

				&:hover,
				&:focus {
					background-color: mix(#000, social-media-color($name, base), 20%);
				}
			}
		}
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3.5rem;
		height: 3.5rem;
		background: $color-blue;
		border-radius: 50%;
		color: $color-neutral;
		font-size: 1.5rem; // 32
		text-align: center;
		text-decoration: none;
		transition: all 150ms ease-in-out;

		&:hover,
		&:focus {
			background: $color-green;
		}
	}
}
