﻿%button {
	position: relative;
	z-index: 1;
	display: inline-block;
	overflow: hidden;
	background: $color-blue;
	color: $color-neutral;
	min-height: 3.5rem;
	padding: 1rem 1.5rem;
	border-radius: $border-radius;
	border: 0;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	line-height: inherit;
	outline: none;
	text-decoration: none;
	vertical-align: top;
	box-sizing: border-box;

	&:hover,
	&:focus {
		&::after {
			width: 100%;
		}
	}

	&::after {
		position: absolute;
		z-index: -1;
		bottom: 0;
		left: 0;
		width: 0;
		height: 100%;
		background: rgba(0, 0, 0, 0.2);
		content: '';
		transition: width 150ms ease-in-out;
	}
}

%button-secondary {
	background-color: $color-green;
}

%button-white {
	background-color: $color-neutral;
	color: $color-blue;
}

%button-dark {
	background-color: mix(#000, $color-blue, 20%);

	&::after {
		background-color: $color-text;
	}
}

%button-outline {
	background: none;
	padding: 0.9375rem 1.4375rem;
	border: 0.0625rem solid $color-blue;
	color: $color-blue;
}

%button-outline-white {
	background: none;
	border: 0.0625rem solid $color-neutral;
	color: $color-neutral;
}

.button {
	@extend %button;

	&.secondary {
		@extend %button-secondary;
	}

	&.white {
		@extend %button-white;
	}

	&.dark {
		@extend %button-dark;
	}

	&.outline {
		@extend %button-outline;

		&.white {
			@extend %button-outline-white;
		}
	}

	&.sell {
		padding-left: 3.5rem;

		&::before {
			@include icon($icon-sell);
			font-size: 1.5rem;
			margin-right: 0.5rem;
			display: block;
			position: absolute;
			top: 50%;
			left: 1.5rem;
			transform: translateY(-50%);
		}

		&:hover,
		&:focus {
			&::before {
				animation: wiggle 1s infinite;
			}
		}
	}

	&.is-loading {
		padding-left: 3rem;

		&:before {
			position: absolute;
			display: block;
			content: '';
			border: 0.25rem solid $color-grey;
			border-radius: 50%;
			border-top: 0.25rem solid #fff;
			width: 1.25rem;
			height: 1.25rem;
			top: 1.25rem;
			left: 1rem;
			animation: spin 1.5s linear infinite;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	&.is-whatsapp,
	&.is-telephone,
	&.is-mail {
		padding: 1.188rem 1.563rem 1.125rem 3.125rem;

		&:after {
			position: absolute;
			top: 50%;
			right: auto;
			left: 1.5rem;
			transform: translateY(-50%);
		}
	}

	&.is-whatsapp {

		&:after {
			@include icon($icon-whatsapp);
		}
	}

	&.is-telephone {

		&:after {
			@include icon($icon-phone);
		}
	}

	&.is-mail {

		&:after {
			@include icon($icon-mail);
		}
	}
}

ul.buttons {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin-top: -1.25rem;
	margin-right: -0.625rem;
	margin-left: -0.625rem;
	list-style: none;

	li {
		margin: 1.25rem 0.625rem 0;
	}
}

@keyframes wiggle {
	0% { transform: translateY(-50%) rotate(0deg); }
   80% { transform: translateY(-50%) rotate(0deg); }
   85% { transform: translateY(-50%) rotate(5deg); }
   95% { transform: translateY(-50%) rotate(-5deg); }
  100% { transform: translateY(-50%) rotate(0deg); }
}