@import '~normalize.css';

// 1. Use the iOS devices hardware accelerator to provide native scrolling.
html {
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch; // 1
	overflow-y: scroll;
}

*,
::before,
::after {
	box-sizing: inherit;
}

::placeholder {
	color: inherit;
	opacity: 0.5;
}

// 1: Improve rendering of screen fonts.
// 2: Prevent page breaking.
h1,
h2,
h3,
h4,
h5,
h6 {
	page-break-after: avoid; // 2
	text-rendering: optimizeLegibility; // 1
}

figure {
	margin: 0;
}

// Remove the gap between images, videos, audio and canvas and the bottom of their containers
audio,
canvas,
iframe,
img,
video,
svg {
	vertical-align: middle;
}

// Make the image responsive
img {
	max-width: 100%;
	height: auto;
}
