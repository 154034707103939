﻿.languages__checkbox {
	display: none;

	&:checked + .languages {

		dt {
			border-radius: 0 0 $border-radius $border-radius;
		}

		dd {
			display: block;
		}

		label {

			&::after {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}

	&:checked + %languages-header-menu {

		dt {
			background: #fff;
		}
	}
}

%languages-side-menu,
%languages-header-menu {
	//@include font-size-button;
	font-weight: 600;
	color: $color-blue;
	margin: 2.5rem 0 0;

	dt,
	dd {
		background: #fff;
	}

	dd {
		display: none;
		position: absolute;
	}

	label {
		cursor: pointer;
	}

	label,
	li a {
		position: relative;

		&:before {
			position: absolute;
			content: '';
			width: 1.5rem;
			height: 0.938rem;
			top: 1.5rem;
			left: 1.5rem;
			background: url(../../images/languages.svg) no-repeat;
			background-size: 1.5rem 4.688rem;
			background-position-y: -1.875rem;
			overflow: hidden;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	a {
		display: block;
		padding: 0.563rem 1.125rem 0.5rem 4.063rem;
		text-decoration: none;
		transition: background 0.3s ease-in-out;
		border: none;

		&:hover,
		&:focus {
			background: rgba($color-blue, 0.15);
		}
	}

	.is-nl {

		&:before {
			background-position-y: 0 !important;
		}
	}

	.is-nl-be {

		&:before {
			background-position-y: -3.75rem;
		}
	}

	.is-pl {

		&:before {
			background-position-y: -2.813rem;
		}
	}

	.is-de {

		&:before {
			background-position-y: -0.938rem;
		}
	}
}

%languages-header-menu {
	display: none;

	@include breakpoint(l) {
		display: block;
	}

	dt {
		//border-radius: $border-radius $border-radius 0 0;
		width: 5rem;
		height: 3.625rem;
		overflow: hidden;
		background: none;

		label {
			display: block;
			width: 100%;
			height: 100%;

			&:before {
				width: 1.875rem;
				height: 1.25rem;
				top: 1.188rem;
				left: 1.125rem;
				background-size: 1.875rem 5.938rem;
				background-position-x: -1px;
				background-position-y: -2.375rem;
				border: 1px solid rgba(#fff, 0.6);
				border-radius: 0.25rem;
			}

			&.is-nl {

				&:before {
					background-position-y: 0;
				}
			}

			&.is-nl-be {

				&:before {
					background-position-y: -4.75rem;
				}
			}

			&.is-pl {

				&:before {
					background-position-y: -3.563rem;
				}
			}

			&.is-de {

				&:before {
					background-position-y: -1.188rem;
				}
			}
		}

		span {
			visibility: hidden;
		}
	}

	dd {
		border-radius: 0 0 $border-radius $border-radius;
		padding: 0.563rem 0;
		top: 100%;
		bottom: auto;
	}

	label,
	li a {

		&:before {
			top: 0.75rem;
		}
	}
}

%languages-side-menu {
	position: relative;
	z-index: 2;
	cursor: pointer;

	dt {
		//border-radius: $border-radius;
		overflow: hidden;
	}

	label {
		display: block;
		padding: 1.188rem 1.25rem 1.125rem 4.5rem;
		cursor: pointer;
		transition: background 0.3s ease-in-out;

		&::after {
			@include icon($icon-arrow-down);
			position: absolute;
			top: 50%;
			right: 1.5rem;
			transform: translateY(-50%);
			transform-origin: center;
			font-size: 1rem;
			color: $color-blue;
		}

		&:hover,
		&:focus {
			background: rgba($color-blue, 0.15);
		}
	}

	dd {
		width: 100%;
		padding: 0;
		border-radius: 0;
		margin: 0;
		bottom: 100%;
		left: 0;
		border-radius: $border-radius $border-radius 0 0;
		overflow: hidden;
	}

	li:last-child a {
		border: none;
	}

	a {
		padding: 1.25rem 1.25rem 1.25rem 4.5rem;
	}

	label,
	li a {

		&:before {
			top: 1.55rem;
		}
	}
}
