﻿.filter-group {
	border-bottom: 0.0625rem solid $color-grey;
	margin: 0;

	&.active-filter {
		.more-toggler {
			display: none;
		}
	}

	dt {
		font-weight: 800;
		font-size: 1.125rem;
		position: relative;
		padding: 0.5rem 2.5rem 0.5rem 0;
		margin: 0;
		transition: color 150ms ease-in-out;

		&:hover,
		&:focus {
			color: $color-green;
		}

		@include breakpoint(l) {
			padding: 1rem 5rem 1rem 0;
		}

		label {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			color: $color-text;
			font-size: 75%;
			cursor: pointer;
			text-align: center;
			transition: color 150ms ease-in-out;

			&:hover,
			&:focus {
				color: $color-green;
			}
		}
	}

	dd {
		display: none;
		margin: 0;

		ul {
			padding: 0;
			margin: 0 0 1.5rem;
			list-style: none;

			li {
				margin-bottom: 0.5rem;

				&.active {
					a::before {
						background: $color-green;
						border: none;
					}

					a::after {
						@include icon($icon-check);
						position: absolute;
						top: 0.375rem;
						left: 0.375rem;
						color: $color-neutral;
						font-size: 0.75rem;
					}
				}

				&:nth-child(n+6) {
					display: none;
				}

				a {
					position: relative;
					font-size: 1rem;
					text-decoration: none;
					display: block;
					padding-left: 2rem;

					&::before {
						position: absolute;
						top: 0;
						left: 0;
						display: block;
						content: '';
						width: 1.5rem;
						height: 1.5rem;
						border: 0.0625rem solid $color-grey;
						border-radius: $border-radius;
					}

					&:hover,
					&:focus {
						&::before {
							border-color: $color-grey-dark;
						}
					}
				}
			}
		}

		.more-toggler {
			display: block;
			margin-top: -0.5rem;
			margin-bottom: 0.5rem;
			cursor: pointer;
			transition: color 250ms ease-in-out;
			font-weight: 800;
			color: $color-green;
			text-transform: lowercase;
			font-size: 1rem;

			&:hover {
				color: $color-blue;
			}

			> span {
				&::before {
					margin-right: 0.5rem;
					content: '+';
				}

				&::after {
					content: attr(data-show-all);
				}
			}
		}

		input[type=checkbox]:checked {
			+ ul {
				li:nth-child(n+6) {
					display: block;
				}
			}

			~ .more-toggler {
				> span {
					&::before {
						content: '-';
					}

					&::after {
						content: attr(data-show-less);
					}
				}
			}
		}
	}
}

input[type=checkbox]:checked + dl.filter-group {
	.icon-chevron-down::before {
		content: $icon-chevron-up;
	}

	dd {
		display: block;
	}
}