﻿.partner-links {
	//@include font-legal;
	display: flex;
	align-items: center;
	margin: 0;

	&.has-background {
		padding: 0.375rem 1.5rem;
		background: $color-blue-dark;

		@include breakpoint(lm) {
			padding: 0.375rem 3.125rem;
		}
	}

	dt {
		padding: 0;
		color: #fff;
	}

	dd {
		margin: 0;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 0.563rem 0 0.688rem;
		margin: 0 1rem 0;
		list-style: none;
		color: #fff;

		a {
			margin: 0 1rem;

			&:hover {
				text-decoration: underline;
			}

			&.kerklaan-horti-materials,
			&.is-mustang-demolition ,
			&.is-kerklaan-greenhouses {
				display: block;
				text-indent: -9999px;
				overflow: hidden;
				background-repeat: no-repeat;
			}

			&.is-kerklaan-greenhouses {
				width: 10.125rem;
				height: 2rem;
				background-image: url(../../images/kerklaan-greenhouses-logo.svg);
			}

			/*&.kerklaan-horti-materials {
				width: rem(163px);
				height: rem(32px);
				background: url(../../images/logo-kerklaan-horti-materials.svg);
			}*/

			&.is-mustang-demolition {
				width: 6.1875rem;
				height: 2.5rem;
				background: url(../../images/logo-mustang-demolition.svg);
			}
		}
	}
}
