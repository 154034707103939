%container,
.container {
	max-width: ($container-max-width + $grid-gap);
	padding: 0 ($grid-gap / 2);
	margin: 0 auto;

	&.fluid {
		max-width: none;
		margin: 0;
	}

	&.small {
		max-width: ($container-small-max-width + $grid-gap); // 1392px
	}

	&.med {
		max-width: ($container-med-max-width + $grid-gap); // 1680px
	}

	&.large {
		max-width: ($container-large-max-width + $grid-gap); // 1416px
	}
}
