// Stack levels/z-indexes (keep below 1000 for lightGallery compatibility)
$z-index: (
	'footer': 10,
	'main': 20,
	'header': 30,
	'logo': 40,
	'tooltip': 50,
	'toast': 60,
	'search-wrapper': 70,
	'search-toggle': 80,
	'autocomplete': 85,
	'nav-wrapper': 90,
	'nav-toggle': 100
);

// Container
$container-max-width: 87rem; // 1392
$container-small-max-width: 57rem; // 912
$container-med-max-width: 88.5rem; // 1416
$container-large-max-width: 105rem; // 1680
$site-max-width: 160rem; // 2560

// Grid
$grid-columns: 12;
$grid-gap: 3rem;

// Primary colors
$color-blue: #005dcc;
$color-blue-dark: #003b82;
$color-green: #17c7a0;
$color-neutral: #fff;
$color-grey: #e5eefa;
$color-grey-dark: #8c9fb7;

// Base
$font-size: 0.875rem; // 14
$line-height: 1.5rem; // 24
$font-family: 'Open Sans', Arial, Helvetica, sans-serif;
$color-text: #001932;
$color-background: $color-neutral;
$color-background-alternate: $color-grey;
$border-radius: 0.25rem; // 4

$background-colors: (
	'blue': (
		'base': $color-blue,
		'button': $color-green,
		'text': $color-neutral
	),
	'green': (
		'base': $color-green,
		'button': $color-blue,
		'text': $color-neutral
	)
);

@function background-color($base, $shade: base) {
	@return map-get(map-get($background-colors, $base), $shade);
}
