﻿@import '~swiper/swiper';
@import '~swiper/components/navigation/navigation';
@import '~swiper/components/pagination/pagination';

.slider {
	position: relative;

	.swiper-slide {
		width: 100%;
	}
}

.thumb-slider {
	margin-bottom: $line-height;
}

.gallery-thumbs {
	margin-top: ($grid-gap / 2);

	.swiper-slide {
		cursor: pointer;
		width: 50%;
		border: 0.125rem solid $color-grey;
		margin-right: 0.75rem;
		box-sizing: border-box;
		max-width: calc((100% / 6) - 10px);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;
			border: 0.125rem solid $color-neutral;
		}

		&:last-of-type {
			margin-right: 0;
		}

		&.swiper-slide-thumb-active {
			border-color: $color-blue;
		}

		&.rest-indicator {
			border: none;
			color: $color-neutral;
			font-weight: 800;

			&::before {
				content: none;
				border: none;
			}

			figcaption {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 1.5rem;

				&::before {
					content: '+';
				}
			}
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	display: none;
	width: 2.5rem;
	height: 2.5rem;
	margin-top: 0;
	border-radius: 50%;
	color: $color-neutral;
	line-height: 2.5rem;
	text-align: center;
	transition: all 150ms ease-in-out;
	display: block;
	margin-top: -(2.5rem / 2);

	&::after {
		@include icon;
		font-size: 1rem;
	}

	&:hover,
	&:focus {
		background: $color-neutral;
		color: $color-blue;
	}

	&.swiper-button-disabled {
		opacity: 0;
	}
}

.swiper-button-prev {
	left: 0.5rem;

	&::after {
		content: $icon-chevron-left;
	}
}

.swiper-button-next {
	right: 0.5rem;

	&::after {
		content: $icon-chevron-right;
	}
}
