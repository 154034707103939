@font-face {
	font-display: swap;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: normal;
	src: url('../../fonts/open-sans-regular.woff2') format('woff2'), url('../../fonts/open-sans-regular.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: url('../../fonts/open-sans-600.woff2') format('woff2'), url('../../fonts/open-sans-600.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../../fonts/open-sans-700.woff2') format('woff2'), url('../../fonts/open-sans-700.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	src: url('../../fonts/open-sans-800.woff2') format('woff2'), url('../../fonts/open-sans-800.woff') format('woff');
}