﻿.image {
	&.is-3by2 {
		@include aspect-ratio(3 / 2);
	}

	img {
		width: 100%;
		height: auto;
	}

	&.video {
		position: relative;
		overflow: hidden;

		a {
			&::before,
			&::after {
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&::before {
				display: block;
				width: 4rem;
				height: 4rem;
				background: $color-neutral;
				border-radius: 2rem;
				content: '';
				text-align: center;

				@include breakpoint(t) {
					width: 6rem;
					height: 6rem;
					border-radius: 3rem;
				}
			}

			&::after {
				@include icon($icon-play);
				padding: 0.063rem 0 0 0.563rem;
				color: $color-blue;
				font-size: 3rem;
				transition: all 150ms ease-in-out;
			}

			&:hover::after,
			&:focus::after {
				color: $color-green;
			}
		}

		&.video-small {
			a {
				&::before {
					width: 1rem;
					height: 1rem;

					@include breakpoint(t) {
						width: 3rem;
						height: 3rem;
					}
				}

				&::after {
					font-size: 0.5rem;

					@include breakpoint(t) {
						font-size: 1.5rem;
						padding-left: 0.375rem;
					}
				}
			}
		}
	}

	&.no-image {
		&::before {
			width: 100%;
			height: 100%;
			background-color: $color-grey;
			background-image: url('../../images/kerklaan-horti-materials-logo.svg');
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: 50% auto;
			content: '';
		}

		figcaption {
			padding-top: calc((100% / 3 * 2) + 0.5rem);

			@include breakpoint(l) {
				padding-top: calc((100% / 3 * 2) + 1.5rem);
			}
		}
	}
}
