html {
	position: relative;
	font-size: 100%;
	scroll-behavior: smooth;
}

body {
	background: $color-background;
	color: $color-text;
	font-family: $font-family;
	font-size: $font-size;
	line-height: $line-height;
}

a {
	color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
pre,
table,
blockquote {
	margin: 0 0 $line-height;
}

ul,
ol {
	ul,
	ol {
		margin: 0;
	}
}
