﻿.filters-responsive-navigation {
	display: block;
	font-size: 1rem;
	padding: 2.5rem 0 2rem;
	margin: 0 -1.5rem;
	list-style: none;
	color: #fff;

	@include breakpoint("l") {
		display: none;
	}

	> li:first-child > a {
		border-top: 1px solid rgba(#fff, 0.1);
	}

	li {

		a {
			transition: background 0.3s ease-in;

			&:hover {
				background: rgba(#000, 0.1);
			}
		}

		&:not(.has-children) {

			a {

				&:after {
					@include icon($icon-arrow-right);
					position: absolute;
					font-size: 0.875rem;
					top: 1.5rem;
					right: 1.625rem;
					transition: right 0.3s ease-in;
				}

				&:hover {
					background: rgba(#000, 0.1);

					&:after {
					}
				}
			}
		}

		&.has-children {
			position: relative;

			> label {
				position: absolute;
				z-index: 1;
				width: 4.25rem;
				height: 4.188rem;
				top: 1px;
				right: 0;
				text-indent: -99999px;
				background: $color-blue;
				transition: background 0.3s ease-in;
				cursor: pointer;

				&:after {
					@include icon($icon-arrow-down);
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 0.875rem;
					text-indent: 0;
				}

				&:hover {
					background: rgba(#000, 0.1);
				}
			}

			> input {
				display: none;

				&:checked ~ ul {
					display: block;
				}
			}

			li:not(.has-children) a {

				&:after {
					top: 1.188rem;
					font-size: 0.875rem;
				}
			}
		}

		> ul {
			list-style: none;
			padding: 0;
			display: none;

			> li.has-children {

				> label {
					height: 2.875rem;
				}

				> ul {

					> li > a {
						padding-left: 3.5rem;
					}
				}
			}

			a {
				//@include font-size-title-small;
				padding: 0.75rem 4.25rem 0.75rem 2.75rem;
			}
		}
	}

	a {
		position: relative;
		display: block;
		z-index: 1;
		position: relative;
		padding: 1.375rem 4.25rem 1.375rem 1.375rem;
		transition: color 0.3s ease-in-out;
		border-bottom: 1px solid rgba(#fff, 0.1);
		text-decoration: none;
	}
}
