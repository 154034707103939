%ir,
.ir {
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
}

%clearfix,
.clearfix::after {
	display: table;
	clear: both;
	content: '';
}

%visuallyhidden,
.visuallyhidden {
	position: absolute;
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
	clip: rect(0 0 0 0);
}
