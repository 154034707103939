@mixin aspect-ratio($ratio) {
	&::after {
		@extend %clearfix;
	}

	&::before {
		width: 1px;
		height: 0;
		padding-top: percentage(1 / $ratio);
		margin-left: -1px;
		content: '';
		float: left;
	}
}
