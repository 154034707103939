form.search {
	position: relative;

	.input {
		margin: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;

		@include breakpoint(t) {
			border-right-width: 3.5rem;
			border-radius: $border-radius;
		}
	}

	.button {
		position: relative;
		width: 100%;
		padding: (0.813rem - 0.0625rem) 0;
		background: $color-green;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		color: $color-neutral;

		@include breakpoint(t) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 3.5rem;
			border-bottom-left-radius: 0;
			border-top-right-radius: $border-radius;
		}

		.icon {
			position: relative;
		}
	}
}

.label {
	font-weight: 700;

	.background-blue,
	.background-green {
		color: $color-neutral;
	}
}

.input,
.textarea {
	overflow: hidden;
	width: 100%;
	min-height: 3.5rem;
	padding: 1rem 1.25rem;
	border: 0.0625rem solid rgba(0, 0, 0, 0.1);
	margin-bottom: ($grid-gap / 2);
	appearance: none;
	background: $color-background;
	border-radius: $border-radius;

	&:focus {
		border-color: $color-green;
		outline: none;
	}
}

.textarea {
	min-height: 10.5rem;
}

.select {
	position: relative;
	display: inline-block;
	max-width: 100%;
	margin-bottom: ($grid-gap / 2);
	vertical-align: top;

	&::after {
		@include icon($icon-chevron-down);
		position: absolute;
		top: 50%;
		right: 1.25rem;
		color: $color-blue;
		pointer-events: none;
		transform: translateY(-50%);
		transition: color 150ms ease-in-out;
	}

	&:hover,
	&:focus::after {
		color: $color-green;
	}

	select {
		position: relative;
		display: block;
		width: 100%;
		padding: 0.813rem 3.75rem 0.813rem 2rem;
		border: 0;
		appearance: none;
		background: $color-background;
		border-radius: $border-radius;
		font-size: 1.25rem;
		line-height: inherit;
		outline: none;
	}
}

.checkbox,
.dataconsent,
.radiobutton-list {
	position: relative;
	width: 100%;
	margin-bottom: ($grid-gap / 4);

	input {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		opacity: 0;

		&:checked + label {
			&::before {
				border: 3px solid $color-neutral;
				background: $color-green;
			}
		}
	}

	label {
		position: relative;
		display: block;
		padding: 0 0 0 2.5rem;
		margin: 0;
		color: $color-text;
		cursor: pointer;

		&::before {
			position: absolute;
			top: 0.25rem;
			left: 0;
			width: 1.25rem;
			height: 1.25rem;
			border: 1px solid $color-neutral;
			background: $color-background;
			content: '';
		}

		&:focus,
		&:hover {
			&::before {
				border-color: rgba($color-blue, 0.25);
			}
		}
	}
}

.radiobutton-list {
	overflow: hidden;
	min-height: 3.5rem;
	padding: 0.75rem 0;

	label {
		margin-right: 2.5rem;
		float: left;

		&::before {
			border-radius: 100%;
		}
	}
}

.checkbox-list {
	margin: 0 0 ($grid-gap / 2);
}

.form {
	.email2 {
		display: none;
	}

	.select {
		width: 100%;

		select {
			background: $color-background;
		}
	}

	.buttons {
		margin-top: 1rem;
	}

	.caption {
		color: $color-neutral;
		font-weight: 800;
		margin-bottom: 0.5rem;
	}
}

.form-description {
	color: $color-grey-dark;
	background: $color-neutral;
	padding: ($grid-gap / 2);
	border-radius: $border-radius;
	margin-bottom: $line-height;
	font-weight: 400;

	h3 {
		color: $color-grey-dark;
	}
}